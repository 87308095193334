
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters(["mdiArrowLeft"]),
  },
})
export default class BackTo extends Vue {
  @Prop({ default: "/search" }) to!: string;
  @Prop({ default: "Back to search" }) label!: string;
}
